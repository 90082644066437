header {
    /* background-color: black; */
    background-color: #FFFFFF;
    display: flex;
    align-content: center;
    justify-content: left;
    padding: 5vh 8vw;
    flex-shrink: 0;
}
div.signature{
    font-family: DM Serif Display;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    margin-right: 4%;
}
div.option{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* line-height: 19px; */
    color: #136333;
    /* width:10%; */
    margin-right:1%;
}
div.signature>a{
    color:black;
}
div.option>a{
    color: #136333;
}

#alternative{
    display:none
}
#full{
    display:flex;
    width:100%
}
@media only screen and (max-width: 600px) {
    #alternative{
        display:flex;
        width:100%;
        background-color: #fff;
    }
    #full{
        display:none
    }
    div.signature{
        width: 100%;
        margin-right: 0;
    }
    header,.activityLink.btn.btn-link{
        padding: 0;
    }
    .card,.card-header{
        background-color: #fff;
    }
}