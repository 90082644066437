#calendarItem{
    border-width:0px;
    /* width:500px; */
    width:100%;
    height:230px;
}
@media only screen and (max-width: 600px) {
    #calendarItem{
        width:100%;
    }
}