
.index {}

.index__title {
    text-align: center;
    text-transform: uppercase;
    display: block;
    margin: auto;
    padding: 3rem 0;
}

/* latin-ext */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('DM Sans Regular'), local('DMSans-Regular'), url(https://fonts.gstatic.com/s/dmsans/v4/rP2Hp2ywxg089UriCZ2IHSeH.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('DM Sans Regular'), local('DMSans-Regular'), url(https://fonts.gstatic.com/s/dmsans/v4/rP2Hp2ywxg089UriCZOIHQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  #lanMesCont{
    background:linear-gradient(0deg, #F9F9F9, #F9F9F9), #F9F9F9;
    height:60vh;
    display:flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
  }
  #landingMessage{
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 113.2%;
    color:#35363F;
    height:40vh;
    width:100%;
    /* padding:20% 5%; */
    position:relative;
  }
  #landingMessage div{
    width:70%;
    position:absolute;
    top:10%;
    left:20%;
}

  #circle {
    position:absolute;
    /* width: 40%; */
    width:40vh;
    height: 40vh;
    right: -20vh;
    top: 10vh;
    background: #ECF3F0;
    border-radius: 50%;
    /* z-index: -1; */
  }

  .cursosContainer1a{
      display:flex;
      align-items: center;
      justify-content: center;
  }
.cursosContainer1b{
    border-radius: 5px;
    background-color:  #ECF3F0;
    display: inline-block;
     /* topbottom leftright */
     padding:10px 30px;
     margin:48px 0;
}
.cursosContainer1b>p{
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 113.2%;
    margin-bottom: 0;
}

.cuadrito{
    background-color: #FFFFFF;
    position: absolute;
    left:30px;
    top:60%;
    border-radius: 5px;
    /* topbottom leftright */
    padding:10px 5px;
    width:80%
    /* width: 102px;
    height: 51px; */
    /* display: flex;
    align-items: center;
    flex-wrap: wrap; */
}
.cuadrito>p{
    color:#000000;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 113.2%;
    margin-bottom: 0;
}
.colClass{
    position:relative;
    margin-bottom: 100px;
}
@media only screen and (min-width: 600px) {
    .index__title {
        font-size: 5rem;
        max-width: 80%;
    }
}


.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* min-width: 100vw; */
  }

.LI-profile-badge{
    text-align: center;
}

/* slides */
.overlay{
    width: 100%;
    height:100%;
    position: absolute;
    z-index:3;
    background-color: rgba(0, 0, 0, 0.9);
}
/* to press buttons */
.slides-navigation{
    z-index:6
}
.slides-navigation .prev,
.slides-navigation .next{
    width:47px;
    height:47px;
    background-size: cover;
    background-repeat: no-repeat;
}
.slides-navigation a.prev{
    left:20px;
    background-image: url("../../assets/images/caroussel/icons/back.png");
}
.slides-navigation a.next{
    right:20px;
    background-image: url("../../assets/images/caroussel/icons/next.png");
}
.titleMessage{
    position:absolute;
    width:100%;
    height:250px;
    top:50%;
    z-index:5;
    text-align: center;
    margin-top: -125px;
}
.titleMessage .heading .main{
    font-size: 50px;
}
.titleMessage .heading .sub{
    font-size: 23px;
}
.titleMessage .heading p{
    color:#fff;
    text-shadow: 0px 2px 5px rgba(0,0,0,0.4);
    font: weight 100;
    letter-spacing: 7px;
}
.section{
    padding:100px 0;
    /* 100px top-bottom 0 left-right 0 */
}

#contactInfo{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #35363F;
    /* display: flex;
    align-items: center; */
}
.iconsImages{
    width:28px;
    height:auto;
    border-radius: 5px;
    margin: 2px 0px;
    margin-right:20px;
}
#social-networks{
    background-color: #F9F9F9;
    min-height:50vh;
    display:flex;
    align-items: center;
}
#myDescription{
    display:flex;
    align-items: center;
}
#myImage{
    margin-left: 20%;
    border-radius: 50%;
    width:150px;
    height:150px;
    margin-right: 3%;
}
#name{
    font-family: DM Serif Display;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 41px;
    color: #35363F;
    margin-bottom: 0;
}
#nameDescription{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #136333;
}
#contactInfo{
    padding:0
}
#myDescription{
    padding: 0;
}
@media only screen and (max-width: 600px) {
    #landingMessage{
        font-size: 20px;
       display: block;
    }
    #landingMessage{
        display:block;
        height:auto;
    }
    #lanMesCont{
        /* height: auto; */
    }
    #landingMessage div {
        width: 80%;
        left:10%;
        top:5%;
        position: relative;
        text-align: center;
    }
    #circle{
        display:none;
    }
    #myImage{
        margin-left: 10vw;
        width:80px;
        height: 80px;
        margin:0;
        margin-right: 2vw;
    }
    #name{
        font-size: 18px;
    }
    #nameDescription{
        font-size: 12px;

    }
    #contactInfo{
        display: flex;
        flex-wrap: wrap;
        padding: 0vh 15vw;
    }
    .infoRow{
        width:50%;
        text-align: left;
    }
    .infoRow{
        font-size: 10px;
    }
    #social-networks{
        min-height: 0vh;
        padding: 3vh 0vw;
    }
    #myDescription{
        padding: 0vh 10vw;
        margin-bottom: 2vh;
    }
    #carousselContainer{
        display: none;
    }
}