footer {
    /* background-color: black; */
    background-color: #212121;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 2vh 4vw;
    flex-shrink: 0;
}
div#center{
    margin-left: 25%;
    width:50%;
    text-align: center;
}
footer>div{
    color:white;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
}

footer span, footer a {
    color: white;
    font-size: large;
}

@media only screen and (max-width: 600px) {
    footer {
        flex-wrap: wrap;
        justify-content:center;
       
        
    }
    div#center{
        width:100%;
        margin-left: 0;
        text-align: left;
    }
    div#developed, div#center{
        text-align: left;
        width:100%;
    }
}