#ActivitiesContainer{
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 113.2%;
    margin-bottom: 0;
    display:flex;
    padding:0 5%;
    text-align: center;
}

#readingsContainer,#videosContainer,#activitiesContainer{
    width:33%;
    padding:0 1%;
}

.reading{
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    color: #35363F;
    /* margin-left:20%; */
    width:60%;
}

@media only screen and (max-width: 600px) {
    #ActivitiesContainer{
        display: flex;
        flex-wrap: wrap;
    }
    #readingsContainer,#videosContainer,#activitiesContainer{
        width:100%;
        margin-bottom: 5vh;
    }
    #presentaciones{
        margin-bottom: 5vh;
    }
    
}