#headerContainer{
    height:60vh;
    background: linear-gradient(0deg, #F9F9F9, #F9F9F9), #F9F9F9;
    display:flex;
    margin-bottom: 5vh;
    /* align-items: center; */
}

#header_title-container{
    width:50%;
    display:flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#header-title{
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 57px;
    color: #35363F;
    width:50%;
    margin-left:20%
}

#header-phrase,#header-phrase-author{
    margin-bottom: 0;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    color: #35363F;
    margin-left:20%;
    width:60%;
}

#header-phrase{
    font-size: 25px;
    line-height: 113.2%;
}

#header-phrase-author{

}

#header_image-container{
    display:flex;
    /* align-items: center; */
    position:relative;
    width:50%
}

#header__course-img{
    width:100%;
    height:100%;
    border-radius: 20px;
    object-fit: cover;
}

#img_author{
    background: linear-gradient(0deg, #F9F9F9, #F9F9F9), #F9F9F9;
    position:absolute;
    bottom:5px;
    right:5px;
    border-radius: 20px;
    padding: 8px 8px;
    margin-bottom:0;
}

@media only screen and (max-width: 600px) {
    #header-title,#header-phrase,#header-phrase-author{
        margin-left:10%;
    }
    #header-phrase,#header-phrase-author{
        width:80%;
    }
    #header_title-container,#header_image-container{
        width:100%;
    }
    #header_image-container{
        display: flex;
        justify-content: center;
        position: relative;
    }
    #header__course-img{
        height: 20vh;
        width:80%
    }
    #headerContainer{
        flex-wrap: wrap;
        height:auto;
        justify-content: center;
        margin-bottom: 0vh;

    }
    #header_title-container{
        margin:3vh 0 ;
    }
    #header-title{
        font-size: 20px;
        line-height: 27px;
    }
    
    #header-phrase,#header-phrase-author{
        font-size: 14px;

    }
    #img_author{
        right:11%;
    }
    #header_image-container{
        margin-bottom: 2vh;
    }
}