#miflex{
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    padding:2px;
}

/* .slideContainer{
    padding:10px;
    background:#000;
    webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    overflow:hidden;  
    } */

    

/* Responsive Container for iFrame */
.embed-container {
    position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; 
   } 
.embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute; top: 0; left: 0; width: 100%; height: 93%;
    }