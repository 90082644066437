/* .home {
    margin-top: -56px;
} */
#miflex{
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}
.col-6{
    width:40%;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    /* max-height: 50vh; */
    height:40vh;
    margin-right: 1rem;
    margin-bottom:1rem;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
}
.page__title {
    text-align: center;
    font-weight: 900;
    display: block;
    width: 60%;
    margin: 3rem auto;
    color: black;
    font-family: 'Open Sans', sans-serif;
}

@media screen and (min-width: 600px) {
    .page__title {
        font-size: 5rem;
    }
}

/* Responsive Container for iFrame */
.embed-container {
     position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; 
    } 
.embed-container iframe, .embed-container object, .embed-container embed {
     position: absolute; top: 0; left: 0; width: 100%; height: 93%;
     }
