.blog__banner {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    overflow: hidden;
    max-height: 30vh;
}

.blog__banner-img {
    width: 100%;
    height: auto;
}

.blog__banner-cover {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.blog__banner-phrase {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-style: italic;
    color: white;
}

.blog__banner-author {
    position: absolute;
    left: 70%;
    top: 90%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #00a7ff;
}

.blog__banner-phrase {
    /* left: 40%; */
    font-size: 1rem;
}
@media screen and (min-width: 600px) {
    .blog__banner-phrase {
        left: 40%;
        font-size: 2rem;
    }
    
}