/* slides */
/* this is my slides div */
.swiper-slide{
    height:60vh;
    width:100%;
}

.slides-container{
    height:100%;
    width:100%;
}

.slides-container>img{
    height:100%;
    width:100%;
}

.overlay{
    width: 100%;
    height:100%;
    position: absolute;
    z-index:3;
    background-color: rgba(0, 0, 0, 0.4);
}

/* to press buttons */
.slides-navigation{
    z-index:6
}

.slides-navigation .prev,
.slides-navigation .next{
    width:47px;
    height:47px;
    background-size: cover;
    background-repeat: no-repeat;
}

.slides-navigation a.prev{
    left:20px;
    background-image: url("../../../assets/images/caroussel/icons/back.png");
}

.slides-navigation a.next{
    right:20px;
    background-image: url("../../../assets/images/caroussel/icons/next.png");
}

.titleMessage{
    position:absolute;
    width:100%;
    height:250px;
    top:50%;
    z-index:5;
    text-align: center;
    margin-top: -125px;
}

.titleMessage .heading .main{
    font-size: 50px;
}

.titleMessage .heading .sub{
    font-size: 23px;
}
.titleMessage .heading p{
    color:#fff;
    text-shadow: 0px 2px 5px rgba(0,0,0,0.4);
    font: weight 100;
    letter-spacing: 7px;
}
.section{
    padding:100px 0;
    /* 100px top-bottom 0 left-right 0 */
}