#imgsContainer{
    padding:0vh 2vw;
    /* padding:0; */
    display:flex;
    flex-wrap:wrap ;
    margin-bottom: 5vh;
}

.imgContainer1a{
    padding: 0vh 2vw;
}

.imgContainer1b{
    position:relative;
    margin-bottom: 2vh;
}
.galleryImage{
    height:auto;
    width:100%;
    border-radius: 10px;
}

.imgContainer1b:hover{
    cursor:pointer;
}

.imgContainer1b:hover>.galleryImage-description{
    opacity: 1;
    z-index: 1;
}
.imgContainer1b:hover>.galleryImage-cover{
    opacity: .5;
}

.galleryImage-cover{
    height:100%;
    width:100%;
    background-color: #000000;
    position: absolute;
    top:0;
    opacity:0;
    border-radius: 10px;
}

.galleryImage-description{
    /* top:0; */
    position: absolute;
    opacity: 0;

    background: linear-gradient(0deg, #F9F9F9, #F9F9F9), #F9F9F9;
    position:absolute;
    bottom:5px;
    right:5px;
    border-radius: 20px;
    padding: 8px 8px;
    margin-bottom:0;
}
@media only screen and (max-width: 600px) {
    .galleryImage{
       width:80%
    }
    .imgContainer1b{
        display: flex;
        justify-content: center;
    }
}
