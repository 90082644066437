.Divider1a{
    display:flex;
    align-items: center;
    justify-content: center;
}

.Divider1b{
  border-radius: 5px;
  background-color:  #ECF3F0;
  display: inline-block;
   /* topbottom leftright */
   padding:10px 30px;
   margin:5vh 0;
}
.Divider1b>p{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 113.2%;
  margin-bottom: 0;
}



@media only screen and (max-width: 600px) {
.Divider1b{

     margin:48px 0;
}
}